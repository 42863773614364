<template>
  <div class="project__description">
    <section
        v-if="project && currentProjectId === project.id" 
        class="project__description__latest__release box" 
    >
        <router-link 
            v-if="project.mintedCount > 0"
            :to="{ name: 'gallery', params: { projectId: project.id } }"
        > 
            view entire gallery
        </router-link>
    </section>

    <section class="project__description video" v-if="project.id == '2'">
        <video controls autoplay muted>
            <source 
                src="https://storage.googleapis.com/eprops-production-bucket/projects/2/resilient_trailer.mp4" 
                type="video/mp4"
            >
        </video>
    </section>

    <section class="project__description details">    
        <div class="project__description__info box" v-if="project">
            <div class="title">
                <h1>{{project.title}}</h1>
                <p>by {{project.author}}</p>
                <div class="title__author">
                    <img src="@/assets/icons/logo-twitter.svg" alt="twitter">
                    <a 
                        :href="author.website"
                        class="author-website"
                        v-for="(author, index) in projectAuthors"
                        :key="index"
                    >
                        {{ author.username }}
                    </a>
                </div>
            </div>

            <div class="image-block-mobile">
                <div class="loading_wrapper" v-if="loadingRandomImg">
                    <loading 
                        position="center"
                    /> 
                </div>
            
                <div v-else class="token-img">
                    <router-link
                        v-if="randomArt"  
                        :to="{
                            path: `/projects/${projectId}/art/${randomArt.id}` 
                        }"
                        
                    >  
                        <img :src="randomArt.previewImageUrl" alt="Preview image">
                    </router-link> 
                    <img v-else :src="project.previewImageUrl" alt="Preview Image">
                </div>

                <div class="img-description" v-if="randomArt">
                    <div  class="img-description-left">
                        <router-link 
                            :to="{
                                path: `/projects/${projectId}/art/${randomArt.id}` 
                            }"               
                        >
                            <span>{{imageTitle}} #{{randomArt.id % 1000000}}</span>
                        </router-link> 
                    </div>
                    <div  class="img-description-right">
                        <a @click="getProjectPreview">Randomize</a>
                    </div>
                </div>
            </div>

            <div class="info">
                <project-info v-if="project.mode == 'standard'">
                    <template slot="content">
                        <p><strong>Total Tokens:</strong>{{project.totalMints}}</p>

                        <!-- <progress-bar-free-mint 
                            v-if="project.status == 'free_mint'"
                            :contract-address='project.snip721ContractAddress'
                        >
                        </progress-bar-free-mint>

                        <progress-bar v-else
                            :status="project.status"
                            :total-mints="project.totalMints"
                            :minted-count="project.mintedCount"
                        >
                        </progress-bar> -->

                        <div v-if="project.status == 'free_mint'">
                            <p v-if="project.startDate != null && project.endDate !== null"><strong>Minting Period:</strong>{{ mintingDate }} </p>
                        </div>

                        <div v-else>
                            <p><strong>Public Mint:</strong>Apr 2nd 12:00pm ET / 16:00 UTC</p>
                        </div>

                        <p><strong v-if="project.copyRight !== null">Copyright:</strong>{{project.copyRight}}</p>

                        <p><strong>Script:</strong>{{project.scriptType}}</p>

                        <p class="last"><strong>Price Per Token:</strong>{{project.pricePerToken}} sSCRT</p>
                    </template>

                </project-info>

                <project-info v-else-if="project.mode == 'dutch_auction' ">
                    <template slot="content">
                        <p><strong>Total Tokens:</strong>{{project.totalMints}}</p>

                        <progress-bar
                            :status="project.status"
                            :total-mints="project.totalMints"
                            :minted-count="project.mintedCount"
                        >
                        
                        </progress-bar>

                        <accordion v-if="!isDutchInfoLoading" title="auction details"> 
                            <template slot="content">
                                <p>
                                    <strong>Total Minted:</strong>
                                    {{project.mintedCount}} / {{project.totalMints}}
                                </p>
                                
                                <p>
                                    <strong>Total Mints:</strong> 
                                    {{project.totalMints}}
                                </p>

                                <p>
                                    <strong>Copyright:</strong>
                                    {{project.copyRight}}
                                </p>

                                <p>
                                    <strong>Script:</strong>
                                    {{project.scriptType}}
                                </p>

                                <!-- Dutch Auction Starts Here -->
                                <p>
                                    <strong>Sale Start:</strong>
                                    {{dutchAuctionInfo.start_time}} UTC
                                </p>

                                <p>
                                    <strong>Phase Duration:</strong>
                                    {{dutchAuctionInfo.time_step}}
                                </p>

                                <p><strong>Phase Increment:</strong>
                                    {{dutchAuctionInfo.price_step}} sSCRT
                                </p>

                                <p>
                                    <strong>Next Phase Starts:</strong>
                                    {{dutchAuctionInfo.next_step}}
                                </p>

                                <p>
                                    <strong>Starting Price:</strong>
                                    {{dutchAuctionInfo.initial_price}} sSCRT
                                </p>
                            </template>
                        </accordion>

                        <p class="last" v-if="!isDutchInfoLoading">
                            <strong>Current Price:</strong>
                            {{ dutchAuctionInfo.current_price / 1000000 }} sSCRT
                        </p>

                    </template>
                </project-info>  
            </div>

            <div class="actions">
                <div class="buttons">
                    <button v-if="!isConnected" @click="bootstrap">
                        CONNECT TO KEPLR
                    </button>
                    <button
                        :class="{ free__mint: isFreeMint}" 
                        v-else-if="!isAllMinted"
                        @click="purchaseNft()"
                        :disabled=" 
                          isFreeMint || 
                          isStudiesLoading || 
                          (!gavePermissions && premint) || 
                          project.status == 'free_mint' || 
                          project.status == 'no_mint' || 
                          project.status == 'upcoming' 
                        "
                    >
                        <span v-if="isFreeMint">MINT CLOSED</span>
                        <span v-else>{{mintButtonTitle}}</span>
                    </button>
                    <button
                        class="btn-gallery" 
                        @click="$router.push({name: 'gallery', params: {projectId: projectId }})"
                    >
                        <span>GALLERY</span>
                    </button>
                </div>

                <!-- <button
                    v-if="isFreeMint" 
                    class="free-mint-message"
                    :disabled="isValidatingPermission || !isConnected"
                    @click="gavePermissions ? purchaseNft() : approveWhiteList()" 
                >
                    Do you own any Big Bangs? <a>Click here</a>  to check free token eligibility 💥=💥💥
                </button> -->
            </div>

            <span v-show="showErrorMessage" class="error-message"> {{errorTxtMessage}} </span>
        
            <span 
                class="all-minted" 
                v-if="isAllMinted"
            >
                All tokens for this project have been minted. 
            </span>

            <description v-if="projectId == '1'"> </description>
            <home-description v-else> </home-description>
        </div>

        <div class="project__description__image" v-if="project" >
            <div class="loading_wrapper" v-if="loadingRandomImg">
                <loading 
                    position="center"
                /> 
            </div>
        
            <div v-else class="token-img">
                <router-link
                    v-if="randomArt"  
                    :to="{
                        path: `/projects/${projectId}/art/${randomArt.id}` 
                    }"
                    
                >  
                    <img :src="randomArt.previewImageUrl" alt="Preview image">
                </router-link> 
                <img v-else :src="project.previewImageUrl" alt="Preview Image">
            </div>

            <div class="img-description" v-if="randomArt">
                <div  class="img-description-left">
                    <router-link 
                        :to="{
                            path: `/projects/${projectId}/art/${randomArt.id}` 
                        }"               
                    >
                        <span>{{imageTitle}} #{{randomArt.id % 1000000}}</span>
                    </router-link> 
                </div>
                <div  class="img-description-right">
                    <a @click="getProjectPreview">Randomize</a>
                </div>
            </div>
        </div>
    </section>  

    <section 
        class="project__description__last__minted" 
        v-if="project && project.mintedCount > 0"
    >
        <last-minted-tokens 
            :projectId="projectId"
            :totalMints="project.totalMints"
            :projectTitle="project.title"
        />
    </section>

    <section
        class="project__description__last__minted"
        v-if="project && project.id === currentProjectId" 
    >
        <upcoming-projects>
        </upcoming-projects>
    </section>

    <modal 
        class="modal" 
        ref="renderingModal" 
        :closable="false" 
        :close-on-outside="false"
    >
      <div class="modal-body">
        <div class="center">
          <h3>{{renderingModalText}}</h3>
          <img class="spinner" src="@/assets/icons/spinner.gif" alt="Rendering Image">
        </div>
      </div>
    </modal>

    <modal 
        class="modal-premint" 
        ref="preminting" 
        :closable="!isLoading" 
        :close-on-outside="false"
    >
      <div class="modal-body">
          <h3 class="modal-title">Free token mint</h3>
          <div v-if="authStudies">  
            <div v-if="isStudiesLoading" class="center">
                <h3>Checking eligibility</h3>
                <img class="spinner" src="@/assets/icons/spinner.gif" alt="Rendering Image">
            </div>

            <div v-else>
                <div class="token-no-eligible" v-if="studiesPendingApproved.length === 0">
                    <alert 
                        background-color="danger"
                        font-color='white'
                        alert-content="Sorry, you’re not eligible to mint a free Big Bang."
                    >
                    </alert>
                    <span> 
                        <a 
                            :href="`https://stashh.io/collection/${project.snip721ContractAddress}`"
                            target="blank"
                        >
                        Visit Stashh </a> 
                        to purchase a Big Bang on the secondary market.
                    </span>
                </div>
                <div class="token-eligible" v-if="studiesPendingApproved.length > 0">
                    <alert 
                        background-color="success"
                        font-color='white'
                        alert-content="Congratulations! You are eligible to mint a free Big Bang."
                    >
                    </alert>
                    <p>Your purchased tokens</p>
                    <select v-model="selectedStudy">
                        <option
                            v-for="item in studiesPendingApproved"
                            :key=item.index
                            :value="item"
                            :selected="selectedStudy"
                        >{{project.title}} #{{item % 1000000 }}
                        </option>
                    </select>
                </div>
            </div>

          </div>

          <div v-else>

            <p v-if="!isLoading">You need a {{authMethod}} to mint your free token.</p>
            <button
                v-if="!isLoading" 
                @click="authenticateStudies()"
            >
                Create {{authMethod}} 
            </button> 
    
            <div v-if="isLoading" class="center creating-vk">
                <p>Please wait while your {{authMethod}} is beign created.</p>
                <img class="spinner" src="@/assets/icons/spinner.gif" alt="Rendering Image">
            </div>
          </div>

          <div class="modal-footer">
            <button
                v-if="authStudies && studiesPendingApproved.length > 0"
                @click="mintFreeTooken(selectedStudy)"  
                class="btn btn--primary"
                :disabled="isStudiesLoading || !selectedStudy || mintinInProgress"
            >
                Mint
            </button>
          </div>
      </div>
    </modal>
  </div>
</template> 

<script>
import Modal from "@/components/Modal";
import Loading from "@/components/Loading";
import Description from '../components/Description.vue';
import LastMintedTokens from '../components/LastMintedTokens.vue';
import Alert from '../components/UiUtils/Alerts/Alert.vue';
import ProgressBarFreeMint from '../components/UiUtils/ProgressBarFreeMInt.vue';
import HomeDescription from '@/components/Home/HomeDescription.vue';
import ProjectInfo from '@/components/Projects/ProjectInfo.vue';
import Accordion from '@/components/UiUtils/Accordion.vue';
import ProgressBar from '@/components/Projects/ProgressBar.vue';
import UpcomingProjects from '@/components/Projects/UpcomingProjects.vue';
import api from "@/service/api.js";
import { sscrt } from "@/contracts/sscrt.js";
import { isUserAuthenticated,authenticateUser, getAuthMethod, currentDate, initContracts } from '@/utils.js';
import { onAccountAvailable, coinConvert, bootstrap, refContract, isAccountAvailable } from '@stakeordie/griptape.js';
import moment from 'moment';

const currentProjectId = process.env.VUE_APP_CURRENT_PROJECT_ID;
const currentTimeSec =  Math.ceil(((Date.now()) / 1000));

export default {
    components: { 
        Modal, 
        Loading, 
        Description, 
        LastMintedTokens,
        Alert,
        ProgressBarFreeMint,
        HomeDescription,
        ProjectInfo,
        Accordion,
        ProgressBar,
        UpcomingProjects 
    },
    data() {
      return {
        projectId: '',
        currentProjectId,
        project: {
            id: 0,
            authorWebsite: ""
        },
        randomArt: null,
        showErrorMessage: false,
        errorTxtMessage: 'An error occurred. Please try again.',
        imageTitle:"",
        //PREMINT
        premint: null,
        authStudies: null,
        gavePermissions: null,
        authMethod:'',
        studiesTokenList: [],
        studiesApprovedList: [],
        studiesPendingApproved: [],
        selectedStudy: null,

        isLoading: false,
        isStudiesLoading: false,
        mintinInProgress: false,
        isValidatingPermission: false,

        loadingRandomImg: false,
        today: currentDate(),

        isConnected: false,
        renderingModalText: null,
        balance: null,

        isDutchInfoLoading: false,
        dutchAuctionInfo: [],
        moment
      }
    },
    async mounted() {
        await this.getProject();
        await initContracts();
        if (isAccountAvailable()) {
            this.init();
        } else {
            onAccountAvailable(() => {
                this.init();
            })
        }
        this.authMethod = getAuthMethod();
        await this.getProjectPreview();
        await this.getDutchInfo(currentTimeSec);
    },
    computed: {
        showNotWhitelisted () {
            return this.premint && this.gavePermissions && this.studiesTokenList.length == 0 || (!this.gavePermissions && this.premint);
        },
        isAllMinted() {
            return this.project.mintedCount >= this.project.totalMints 
        },
        mintButtonTitle() {
            return this.premint ? 'PRE-MINT' : 'MINT';
        },
        isFreeMint() {
            return process.env.VUE_APP_IS_FREE_MINT == 'true' && this.project.status == 'free_mint' && this.projectId == 1;
        },
        mintingDate() {
            const startDate = moment(this.project.startDate ).format("LL");
            const endDate = moment(this.project.endDate ).format("LL");
            return startDate + " - " + endDate;
        },
        projectAuthors(){
            return this.project.authorWebsite.split(",").map((res) => {
                return {
                    website: res,
                    username: "@" + res.slice(20),
                }
            });
        }
    },
    methods: {
      bootstrap,
      async init() {
          this.isConnected = true;
          await this.isStudiesAuth();
          await this.getStudies();
          await this.getWhiteListApproval();
          if (this.project.mintingContractAddress !== '') {
              await this.isPremint();
          }
          const { balance: { amount } } = await sscrt.getBalance();
          this.balance = parseInt(amount) / 1000000;
      },
      async getProject() {
        this.projectId = this.$route.params.projectId;
        this.project = await api.projects.get(this.projectId);
      },
      async purchaseNft() {
        let contractResponse;
        let parsed;
        try {
            if(this.isFreeMint){
                await this.openPremintModal()
                return
            }
          this.renderingModalText = "Minting"
          this.$refs['renderingModal'].showModal();
          /* const amount = "0"; */
          let amount = '0';
          if(this.project.status == 'free_mint') {
            amount = '0'
          }
          else if(this.project.mode == 'standard') {
            amount = coinConvert(this.project.pricePerToken, 6, 'machine')
          }else if(this.project.mode == 'dutch_auction') {
            amount = this.dutchAuctionInfo.current_price.toString();
          }

          contractResponse = await sscrt.send(this.project.mintingContractAddress, amount);
          parsed = contractResponse.parse();
          const raw = contractResponse.getRaw();
          const artId = raw.logs[0].events[1].attributes[4].value.trim();
          const command = { artId };
          await api.projects.arts.reserve(this.projectId, command);
        } catch (error) {
          console.error(error);
          if (!error.toString().match(/Reject/gi)) {
            this.showErrorMessage = true;
          }
          this.$refs['renderingModal'].hideModal();
          return;
        }

        const { send } = parsed;
        const { status } = send;

        this.$refs['renderingModal'].hideModal();
        if (status === 'success') {
          await this.$router.push({name: 'myemprops', query: {success: true}, params: { projectId: this.projectId}});
        }
        await this.getBalance();
      },
      async mintStudyNft(studyId) {
        this.isStudiesLoading = true;
        const str = JSON.stringify({"receive_mint":{"study_id":studyId}})
        let msg = Buffer.from(str).toString('base64')

        let contractResponse;
        let parsed;
        try {
          const amount = "0";
          contractResponse = await sscrt.send(this.project.mintingContractAddress, amount, msg);
          parsed = contractResponse.parse();
          const raw = contractResponse.getRaw();
          const artId = raw.logs[0].events[1].attributes[4].value.trim();
          const command = { artId };
          await api.projects.arts.reserve(this.projectId, command);
        } catch (error) {
          console.error(error);
          this.showErrorMessage = true;
          this.$refs['preminting'].hideModal();
          return;
        }

        const { send } = parsed;
        const { status } = send;

        this.$refs['preminting'].hideModal();
        if (status === 'success') {
          await this.$router.push({name: 'myemprops', query: {success: true}, params: { projectId: this.projectId}});
        }
      },
      async mintFreeTooken(tokenId) {
        this.mintinInProgress = true;
        this.$refs['preminting'].hideModal();
        this.$refs['renderingModal'].showModal();
        this.renderingModalText = 'Minting in progress';
        const str = JSON.stringify({"receive_mint":{"study_id":tokenId}})
        let msg = Buffer.from(str).toString('base64')

        let contractResponse;
        let parsed;
        try {
          const amount = "0";
          contractResponse = await sscrt.send(this.project.mintingContractAddress, amount, msg);
          parsed = contractResponse.parse();
          const raw = contractResponse.getRaw();
          const artId = raw.logs[0].events[1].attributes[4].value.trim();
          const command = { artId };
          await api.projects.arts.reserve(this.projectId, command);
        } catch (error) {
          console.error(error);
          this.showErrorMessage = true;
          this.mintinInProgress = false;
          this.$refs['renderingModal'].hideModal();
          this.$refs['preminting'].showModal();
          return;
        }

        const { send } = parsed;
        const { status } = send;

        this.$refs['preminting'].hideModal();
        if (status === 'success') {
          await this.$router.push({name: 'myemprops', query: {success: true}, params: { projectId: this.projectId}});
        }
      },
      async getProjectPreview() {
        this.loadingRandomImg = true;
        try {
            this.randomArt = await api.projects.arts.getRandomImage(this.projectId);
            if(this.randomArt.isTestArt){
                this.imageTitle = `${this.project.title} Test Mint`
            }else{
                this.imageTitle = this.project.title
            }
        }catch(error) {
            console.error(error)
        }finally {
            this.loadingRandomImg = false;
        }
      },
      async isPremint() {
        const mintingContract = refContract(this.project.mintingContractAddress);
        const res = await mintingContract.getIsPremint();
        this.premint = res.value; 
      },
      async approveWhiteList() {
        const mintingContract = refContract(this.project.mintingContractAddress);
        const contractAddress = refContract(this.project.snip721ContractAddress);
        this.renderingModalText = "Checking eligibility"
        this.$refs['renderingModal'].showModal();
        try {
            await contractAddress.setWhiteListedApproval(mintingContract.at, null,'all');
            this.openPremintModal();
        }catch(error){
            this.showErrorMessage = true;
        }finally {
            this.$refs['renderingModal'].hideModal();
        }
      },
      async getWhiteListApproval() {
          const contractAddress = refContract(this.project.snip721ContractAddress);
          this.isValidatingPermission = true;
          try {
              const { inventory_approvals : res } = await contractAddress.getInventoryApprovals();
              const { inventory_approvals } = res;
              //search for the correct permission type
              this.gavePermissions = inventory_approvals.some(el => el.address === this.project.mintingContractAddress && el.view_owner_expiration == 'never')
          } catch (error) {
              console.error(error)
              this.gavePermissions = false;
          } finally {
              this.isValidatingPermission = false;
          }
        
      },
      async authenticateStudies() {
        const contractAddress = refContract(this.project.snip721ContractAddress);
        this.isLoading = true;
        try {
            await authenticateUser(contractAddress,['owner'],721);
            await this.isStudiesAuth();
            await this.getAllPendingStudies();
            await this.getWhiteListApproval()
        }catch (error) {
            this.showErrorMessage = true;
        }finally {
            this.isLoading = false;
        }
      },
      async isStudiesAuth() {
        const contractAddress = refContract(this.project.snip721ContractAddress);
        this.authStudies = await isUserAuthenticated(contractAddress);
      },
      async getStudies() {
        const contractAddress = refContract(this.project.snip721ContractAddress);
        const res = await contractAddress.getTokens();
        this.studiesTokenList = res.token_list.tokens;
      },
      async getApprovedStudies() {
        const mintingContract = refContract(this.project.mintingContractAddress);
        const res = await mintingContract.approvedStudies();
        this.studiesApprovedList = res.list;
      },
      async openPremintModal() {
        this.$refs['preminting'].showModal();
        await this.getAllPendingStudies();
      },
      getPendingTokens() {
        const tokens = this.studiesTokenList;
        const approved = this.studiesApprovedList;
        let pendingStudies = tokens.filter(token => !approved.includes(token));
        this.studiesPendingApproved = pendingStudies;
      },
      async getAllPendingStudies() {
          this.isStudiesLoading = true;
          const mintingContract = refContract(this.project.mintingContractAddress);
          const contractAddress = refContract(this.project.snip721ContractAddress);
          try {
            const tokens = await contractAddress.getTokens();
            this.studiesTokenList = tokens.token_list.tokens;
            const approvedTokens = await mintingContract.approvedStudies();
            this.studiesApprovedList = approvedTokens.list;
            let pendingStudies = this.studiesTokenList.filter(token => 
                !this.studiesApprovedList.includes(token) && 
                token <= parseInt(process.env.VUE_APP_STUDIES_ID_LIMIT));
            this.studiesPendingApproved = pendingStudies.sort();
            this.selectedStudy = this.studiesPendingApproved[0];
          }
          catch(error) {
              console.error(error)
          }finally{
              this.isStudiesLoading = false;
          }
      },
      async getBalance() {
        this.balanceIsLoading = true;
        try {
            const response = await sscrt.getBalance();
        if (response) {
            this.$root.sscrtBalance = coinConvert(response?.balance?.amount, 6, 'human', 2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        }catch(error) {
            console.error(error);
        }finally {
            this.balanceIsLoading = false;
        }
      },
      async getDutchInfo(time) {
        const mintingContract = refContract(this.project.mintingContractAddress);
        this.isDutchInfoLoading = true;
        try {
            const data  = await mintingContract.getDutchAuctionInfo(time);
            const { 
                current_price, 
                has_ended, 
                initial_price, 
                num_minted_tokens,
                price_step,
                start_time,
                time_step,
                total_mint_allocation,
            } = data;
            this.dutchAuctionInfo = {
                current_price: parseInt(current_price), 
                has_ended, 
                initial_price: coinConvert(initial_price, 6, 'human'), 
                num_minted_tokens,
                price_step: coinConvert(price_step, 6, 'human'),
                start_time: moment.utc(start_time*1000).format('lll'),
                time_step: moment.utc(time_step*1000).format('HH:mm:ss'),
                total_mint_allocation,
                next_step: moment.utc(((time_step*1000)) - Date.now()).format('HH:mm:ss'),
            }
        } catch(error) {
            console.error('Dutch Error',error);
        }finally {
            this.isDutchInfoLoading = false;
        }
      },
    }
}
</script>

<style lang="scss" scoped>
.project__description {
    display: grid;
    grid-template-rows: (2, 1fr);
    max-width: 1200px;
    margin: 0 auto;
    .box {
        padding: 16px;
    }
    .details {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 48px;
        margin: 0px;
    }
    .video {
        margin: 0px;
        padding: 16px ;
        padding-bottom: 0;
        video {
            width: 100%;
            height: auto;
        }
    }
    &__latest__release {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 0 !important;
        h1 {
            margin: 0px;
            line-height: 46px;
            font-style: italic;
        }
        a {
            color: var(--color-emerald);
            text-decoration: none;
        }
    }
    &__info {
        display: flex;
        flex-direction: column;
        gap: 26px;
        .title {
            h1 {
                font-size: 38px;
                font-weight: 700;
                line-height: 46px;
                font-style: italic; 
                margin-bottom: 8px;
            }
            p {
                margin-bottom: 8px;
                font-weight: 700;
            }
            &__author {
                display: flex;
                gap: 8px;
                align-items: center;
                flex-wrap: wrap;
                a {
                    color: var(--color-emerald);
                    font-weight: 400;
                    text-decoration: none;
                }
            }
        }
        .image-block-mobile {
            display: none;
        }
        .info {
            p {
                strong {
                    font-weight: 700;
                    padding-right: 8px;
                    line-height: 24px;
                }
                &.last {
                    margin-bottom: 0px;
                }
            }
        }
        .actions {
            .buttons{
                display: flex;
                align-items: center;
                column-gap: 10px;
                span.warn {
                    width: 100%;
                    color: var(--color-neutral-black);
                    background: var(--color-yellow);
                    align-items: center;
                    padding: 7px 10px;
                    line-height: 24px;
                }
                button {
                    padding: 11px;
                    line-height: 24px;
                    text-transform: uppercase;
                    font-size: 16px;
                    min-width: 120px;
                    height: 46px;
                    &.btn-gallery {
                        background-color: transparent;
                        border: black 1px solid;
                        color: black;

                        &:hover {
                            background-color: black;
                            color: white;
                        }
                    }
                    &.free__mint {
                        font-weight: 700;
                        background-color: var(--color-neutral-03);
                        color: var(--color-neutral-02);
                    }
                }
            }
        }
        .premint {
            p {
                margin: 16px 0px 4px 0px;
            }
            a {
                cursor: pointer;
                text-decoration: underline;
                color: var(--color-emerald);
                font-weight: normal;
                line-height: 18px;
            }
        }
        button  {
            &.free-mint-message {
                background-color: var(--color-yellow);
                margin-top: 16px;
                padding: 10px;
                width: 100%;
                border-radius: 0px;
                text-align: left;
                font-weight: normal;
                color: var(--color-neutral-black);
                line-height: 24px;
                cursor: pointer;
                border-radius: 8px;
                font-size: 16px;
                font-style: normal;
                a {
                    color: var(--color-neutral-black);
                    text-decoration: underline;
                    cursor: pointer;
                }
                &:disabled {
                    a {
                        cursor: default;
                    }
                }
                @include respond-to("medium and down") { 
                    display: none;
                }
            }
        }
        .author-website {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            color: #5D5FEF;
        }
        .error-message {
            height: 44px;
            display: block;
            color: white;
            background-color: #D73333;
            padding: 15px 10px;
        }
        button {
            cursor: pointer;
            font-weight: 700;
            padding: 11px 20px;
            margin: 0px;
            width: max-content;
            line-height: 24px;
            border-radius: 8px;
        &:disabled {
            cursor: unset;
        }
        }
        .all-minted {
            background-color: #FFD72E;
            padding: 7px 10px;
            display: block;
            width: 100%;
            font-size: 16px;
            line-height: 24px;
            font-style: normal;
            font-weight: 400;
        }
    }
    &__image {
        padding: 16px;
        position: sticky;
        top: 150px;
        align-self: start;
        padding: 16px;
        .loading_wrapper{
            height: 425px;
        }
        .token-img {
            height: auto;
            img {
                width: 100%;
                height: auto;
            }
        }
        .img-description {
                display: flex;
                justify-content: space-between;
                background: transparent;
                margin-top: 15px;
                p {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                }
                a {
                    cursor: pointer;
                    text-transform: lowercase;
                    color: var(--color-emerald);
                    font-weight: 400;
                    text-decoration: none;
                span {
                    text-transform: capitalize;
                }
            }
        }
    }
}
//Modals
.modal::v-deep {
  h3 {
    margin: 0px;
    line-height: 33px;
  }
  .modal__content {
    width: 600px;
    padding: 24px;
    border-radius: 0;
  }
  .modal-body {
    .modal-title {
      margin-bottom: 26px;
    }
    .instructions {
      color: #808080;
      margin-bottom: 26px;
      &--no-margin {
        margin-bottom: 0;
      }
    }
    .instructions-list {
      margin-bottom: 26px;
      list-style: decimal;
      padding-left: 22px;
      li {
        color: #808080;
        margin-bottom: 0;
      }
    }
    .center {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 26px;
      .spinner {
        height: 62px;
        padding: 0;
        width: 64px;
      }
    }
    .creating-vk {
        p {
            margin: 0px;
        }
    }
  }
  .modal-footer {
    text-align: right;
    .btn {
      padding: 11px 20px;
      margin-bottom: 0;
      &--secondary {
        background-color: white;
        border: 1px solid black;
        color: black;
        margin-right: 9px;
      }
      &--primary {
        border: 1px solid black;
      }
    }
  }
}
.modal-premint {
	.modal-body {
		h3 {
			&.modal-title {
				margin-bottom: 24px;
			}
		}
		p {
			margin-bottom: 24px;
		}
        .token-no-eligible {
            display: flex;
            flex-direction: column;
            row-gap: 24px;
            a {
                color: var(--color-neutral-black);
            }
        }
        .token-eligible {
            p {
                margin-top: 24px;
                margin-bottom: 8px;
            }
            select {
                /* all: unset; */
                background-color: transparent;
                border: 1px solid #000000;
                padding: 11px 16px;
                margin-bottom: 24px;
                height: 46px;
            }
        }
        button {
            padding: 11px 20px;
            border-radius: 8px;
            margin: 0px;
            text-transform: uppercase;
            font-weight: bold;
            line-height: 24px;
            width: 120px;
            height: 46px;
            width: auto;
            min-width: 120px;
        }
	}
}
@include respond-to("medium and down") {
  .project__description {
    &__info {
        button {
            display: none;

            &.btn-gallery {
                display: block;
            }
        }
    }
  }
}
@include respond-to("xsmall and down") {
    .project__description {
        margin: 0px;
        .details {
            grid-template-columns: unset
        }
        &__latest__release {
            flex-direction: column;
            justify-content: flex-start;
            row-gap: 8px;
            align-items: flex-start;
        }
        &__info {
            .image-block-mobile {
                display: block;
                .token-img {
                    height: auto;
                    img {
                        width: 100%;
                        object-fit: cover;
                         @include respond-to("small and down") {
                            width: 358px;
                            height: 358px;
                        }
                    }
                }
                .img-description {
                    margin-top: 15px;
                    display: flex;
                    justify-content: space-between;
                    background: transparent;
                    p {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                    }
                    a {
                        cursor: pointer;
                        text-transform: lowercase;
                        color: var(--color-emerald);
                        font-weight: 400;
                        text-decoration: none;
                        span {
                            text-transform: capitalize;
                        }
                    }
                }
            }
            button {
                display: none;
            }
        }
        &__image {
            display: none;
        }
    }
    .modal::v-deep {
      .modal__content {
        width: 90vw;
      }
    }
}
</style>
